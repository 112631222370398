<section class="main">
  <section class="sidebar">
    <section class="sidebar__wrap">
      <section class="sidebar__top">
        <ul class="sidebar__list" name="sidebar__list">
          <li (click)="drawer.toggle()" class="sidebar__list-item" name="fileNavDashboard" *ngIf="false">
            <a class="sidebar__list-link" [class.is-active]="">
              <svg class="sidebar__link-icon" width="24" height="24" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    d="M21.5 18C21.6326 18 21.7598 18.0527 21.8536 18.1464C21.9473 18.2402 22 18.3674 22 18.5C22 18.6326 21.9473 18.7598 21.8536 18.8536C21.7598 18.9473 21.6326 19 21.5 19H2.5C2.36739 19 2.24021 18.9473 2.14645 18.8536C2.05268 18.7598 2 18.6326 2 18.5C2 18.3674 2.05268 18.2402 2.14645 18.1464C2.24021 18.0527 2.36739 18 2.5 18H21.5ZM21.5 11C21.6326 11 21.7598 11.0527 21.8536 11.1464C21.9473 11.2402 22 11.3674 22 11.5C22 11.6326 21.9473 11.7598 21.8536 11.8536C21.7598 11.9473 21.6326 12 21.5 12H2.5C2.36739 12 2.24021 11.9473 2.14645 11.8536C2.05268 11.7598 2 11.6326 2 11.5C2 11.3674 2.05268 11.2402 2.14645 11.1464C2.24021 11.0527 2.36739 11 2.5 11H21.5ZM21.5 4C21.6326 4 21.7598 4.05268 21.8536 4.14645C21.9473 4.24021 22 4.36739 22 4.5C22 4.63261 21.9473 4.75979 21.8536 4.85355C21.7598 4.94732 21.6326 5 21.5 5H2.5C2.36739 5 2.24021 4.94732 2.14645 4.85355C2.05268 4.75979 2 4.63261 2 4.5C2 4.36739 2.05268 4.24021 2.14645 4.14645C2.24021 4.05268 2.36739 4 2.5 4H21.5Z"
                    id="icon-list-view-a" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                  <mask id="icon-list-view-b" fill="#fff">
                    <use xlink:href="#icon-list-view-a" />
                  </mask>
                  <g mask="url(#icon-list-view-b)" fill="#fff">
                    <path d="M0 0h24v24H0z" />
                  </g>
                </g>
              </svg>
              <span class="sidebar__link-title">Menu</span>
            </a>
          </li>
          <li class="sidebar__list-item" name="fileNavDashboard" (click)="redirectToDashBoard()">
            <a class="sidebar__list-link" [class.is-active]="">
              <svg class="sidebar__link-icon" width="24" height="24" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    d="M22 15a1 1 0 011 1v6a1 1 0 01-1 1h-8a1 1 0 01-1-1v-6a1 1 0 011-1h8zm-12-4a1 1 0 011 1v10a1 1 0 01-1 1H2a1 1 0 01-1-1V12a1 1 0 011-1h8zm12 5h-8v6h8v-6zm-12-4H2v10h8V12zM22 1a1 1 0 011 1v10a1 1 0 01-1 1h-8a1 1 0 01-1-1V2a1 1 0 011-1h8zm0 1h-8v10h8V2zM10 1a1 1 0 011 1v6a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h8zm0 1H2v6h8V2z"
                    id="icon-dashboard-a" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                  <mask id="icon-dashboard-b" fill="#fff">
                    <use xlink:href="#icon-dashboard-a" />
                  </mask>
                  <g mask="url(#icon-dashboard-b)" fill="#fff">
                    <path d="M0 0h24v24H0z" />
                  </g>
                </g>
              </svg>
              <span class="sidebar__link-title">Dashboard</span>
            </a>
          </li>
          <li (click)="navigateToAdmin()" class="sidebar__list-item" name="admin" *ngIf="(userInfo?.flags?.isUserAdmin || userInfo?.flags?.isSystemAdmin)">
            <a class="sidebar__list-link" [class.is-active]="">
              <svg _ngcontent-erx-c6="" width="32" height="32" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-erx-c6=""
                  d="M3 17.5V18.5C2.99996 18.617 3.04097 18.7304 3.11589 18.8203C3.19081 18.9102 3.29489 18.9709 3.41 18.992L3.5 19H20.5C20.617 19 20.7304 18.959 20.8203 18.8841C20.9102 18.8092 20.9709 18.7051 20.992 18.59L21 18.5V17.5H22V18.5C22 18.8729 21.8611 19.2324 21.6104 19.5084C21.3597 19.7844 21.0152 19.9572 20.644 19.993L20.5 20H3.5C3.12712 20 2.76761 19.8611 2.49158 19.6104C2.21555 19.3597 2.0428 19.0152 2.007 18.644L2 18.5V17.5H3ZM19 5C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V16C20 16.2652 19.8946 16.5196 19.7071 16.7071C19.5196 16.8946 19.2652 17 19 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19ZM19 6H5V16H19V6Z"
                  fill="currentColor"></path>
                <path _ngcontent-erx-c6=""
                  d="M3 17.5V18.5C2.99996 18.617 3.04097 18.7304 3.11589 18.8203C3.19081 18.9102 3.29489 18.9709 3.41 18.992L3.5 19H20.5C20.617 19 20.7304 18.959 20.8203 18.8841C20.9102 18.8092 20.9709 18.7051 20.992 18.59L21 18.5V17.5H22V18.5C22 18.8729 21.8611 19.2324 21.6104 19.5084C21.3597 19.7844 21.0152 19.9572 20.644 19.993L20.5 20H3.5C3.12712 20 2.76761 19.8611 2.49158 19.6104C2.21555 19.3597 2.0428 19.0152 2.007 18.644L2 18.5V17.5H3ZM19 5C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V16C20 16.2652 19.8946 16.5196 19.7071 16.7071C19.5196 16.8946 19.2652 17 19 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19ZM19 6H5V16H19V6Z"
                  fill="currentColor"></path>
              </svg>
              <span class="sidebar__link-title">Admin</span>
            </a>
          </li>
        </ul>
      </section>
    </section>
  </section>
  <section class="app-content">
    <mat-drawer-container class="side-nav-container" autosize>
      <mat-drawer #drawer class="sidenav-drawer" mode="side">
        <nav>
          <ul class="nav">
            <li>
              <a (click)="drawer.toggle()"> <svg class="sidebar__link-icon" width="18" height="18" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path
                      d="M3.646 3.64592C3.69245 3.59935 3.74762 3.56241 3.80837 3.5372C3.86911 3.512 3.93423 3.49902 4 3.49902C4.06577 3.49902 4.13089 3.512 4.19163 3.5372C4.25238 3.56241 4.30755 3.59935 4.354 3.64592L12 11.2929L19.646 3.64592C19.7289 3.56324 19.8381 3.51226 19.9547 3.50184C20.0713 3.49142 20.1878 3.52224 20.284 3.58892L20.354 3.64592C20.4006 3.69236 20.4375 3.74754 20.4627 3.80828C20.4879 3.86903 20.5009 3.93415 20.5009 3.99992C20.5009 4.06568 20.4879 4.13081 20.4627 4.19155C20.4375 4.2523 20.4006 4.30747 20.354 4.35392L12.707 11.9999L20.354 19.6459C20.4367 19.7288 20.4877 19.838 20.4981 19.9546C20.5085 20.0712 20.4777 20.1877 20.411 20.2839L20.354 20.3539C20.3076 20.4005 20.2524 20.4374 20.1916 20.4626C20.1309 20.4878 20.0658 20.5008 20 20.5008C19.9342 20.5008 19.8691 20.4878 19.8084 20.4626C19.7476 20.4374 19.6924 20.4005 19.646 20.3539L12 12.7069L4.354 20.3539C4.27114 20.4366 4.16191 20.4876 4.04533 20.498C3.92874 20.5084 3.8122 20.4776 3.716 20.4109L3.646 20.3539C3.59944 20.3075 3.56249 20.2523 3.53729 20.1916C3.51208 20.1308 3.49911 20.0657 3.49911 19.9999C3.49911 19.9342 3.51208 19.869 3.53729 19.8083C3.56249 19.7475 3.59944 19.6924 3.646 19.6459L11.293 11.9999L3.646 4.35392C3.56332 4.27106 3.51234 4.16183 3.50192 4.04524C3.49151 3.92866 3.52232 3.81212 3.589 3.71592L3.646 3.64592Z"
                      id="icon-close-a" />
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <mask id="icon-close-b" fill="#fff">
                      <use xlink:href="#icon-close-a" />
                    </mask>
                    <g mask="url(#icon-close-b)" fill="#fff">
                      <path d="M0 0h24v24H0z" />
                    </g>
                  </g>
                </svg></a>
            </li>
          </ul>
        </nav>
      </mat-drawer>
    </mat-drawer-container>
  </section>
</section>
