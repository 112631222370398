import { environment } from './../environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { getUserInfo } from '@igps/client-root-config'
import { UserInfo } from './models/userInfo';
import { NavigationStart, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'client-navigation',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'NavigationBar-UI';
  userInfo!: UserInfo;
  routerSubscription!: Subscription;

  constructor(private router: Router) {
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.userInfo = getUserInfo();
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe((event) => {
      if((event as NavigationStart).url === `/manager/dashboard` || (event as NavigationStart).url === `/processor/dashboard`){
        if(!!sessionStorage.getItem("isReloadRequired") && sessionStorage.getItem("isReloadRequired") === "true"){
          sessionStorage.removeItem("isReloadRequired");
          window.location.href = `${window.location.origin}${(event as NavigationStart).url}`;
        }
      }
    });
  }


  redirectToDashBoard() {
    if (this.userInfo.assumedRoleId === 1) {
      window.location.href = `${window.location.origin}/manager/dashboard`;
    }
    else {
      window.location.href = `${window.location.origin}/processor/dashboard`;
    }
  }

  navigateToAdmin(){
    window.open(environment.adminURL, 'igps-admin')?.focus();
  }

}
